import ForumCategory from "@/models/ForumCategory";
import ForumTopic from "@/models/ForumTopic";
import Pagination from "@/models/Pagination";
import BaseService from "./BaseService";
import {
    ForumRequest,
    ForumResponse,
    ForumsResponse,
} from "./mappers/ForumMapper";

class ForumService extends BaseService {
    constructor() {
        super();
    }

    getForums(pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(
            `forums?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
        )
            .then((response) => response.data)
            .then((response) => {
                let forums = ForumsResponse(response.results);
                let pagination = new Pagination(response);
                return { forums, pagination };
            });
    }

    getForum(id) {
        return this.get(`forums/${id}`).then((response) =>
            ForumResponse(response.data)
        );
    }

    deleteForum(id) {
        return this.delete(`forums/${id}`);
    }

    createForum(data) {
        return this.post(`forums`, ForumRequest(data));
    }

    updateForum(id, data) {
        return this.patch(`forums/${id}`, ForumRequest(data));
    }

    getForumCategories(pagination = false, sorting) {
        let s = sorting;
        let action;
        if (pagination) {
            let p = pagination;
            action = this.get(
                `forum-categories?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
            );
        } else {
            action = this.get(`forum-categories?page=1&per_page=100&sort=name`);
        }

        return action
            .then((response) => response.data)
            .then((response) => {
                let forumCategories = response.results.map(
                    (fc) => new ForumCategory(fc)
                );
                let pagination = new Pagination(response);
                return { forumCategories, pagination };
            });
    }

    getForumCategory(id) {
        return this.get(`forum-categories/${id}`);
    }

    deleteForumCategory(id) {
        return this.delete(`forum-categories/${id}`);
    }

    createForumCategory(data) {
        return this.post(`forum-categories`, data);
    }

    updateForumCategory(id, data) {
        return this.patch(`forum-categories/${id}`, data);
    }

    getPublicForumCategories(pagination = false, sorting) {
        let s = sorting;
        let action;
        if (pagination) {
            let p = pagination;
            action = this.get(
                `public/forum-categories?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
            );
        } else {
            action = this.get(
                `public/forum-categories?page=1&per_page=100&sort=${s.sort}`
            );
        }

        return action
            .then((response) => response.data)
            .then((response) => {
                let forumCategories = response.results.map(
                    (fc) => new ForumCategory(fc)
                );
                let pagination = new Pagination(response);
                return { forumCategories, pagination };
            });
    }

    getPublicForum(id) {
        return this.get(`public/forums/${id}`).then((response) =>
            ForumResponse(response.data)
        );
    }

    createPublicForumTopicForForum(id, data) {
        return this.post(`public/forums/${id}/topics`, data);
    }

    getPublicForumTopicsForForum(id, pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(
            `public/forums/${id}/topics?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
        )
            .then((response) => response.data)
            .then((response) => {
                let forumTopics = response.results.map(
                    (ft) => new ForumTopic(ft)
                );
                let pagination = new Pagination(response);
                return { forumTopics, pagination };
            });
    }

    getPublicForumTopic(id) {
        return this.get(`public/forum-topics/${id}`).then(
            (response) => new ForumTopic(response.data)
        );
    }
}

export default new ForumService();
