import BaseModel from "@/models/BaseModel";
import Comment from "@/models/Comment";
import Forum from "@/models/Forum";
import moment from 'moment';

export class ForumTopic extends BaseModel {
    constructor({
        id,
        name,
        status,
        created,
        modified,
        num_posts,
        last_post,
        closed,
        sticky,
        type,
        forum,
        forum_id,
        comment,
        comment_id
    } = {}) {
        super();

        this.id = id;
        this.name = name;
        this.status = status;
        this.created = moment(created);
        this.modified = moment(modified);
        this.num_posts = num_posts;
        this.last_post = last_post ? new Comment(last_post) : null;
        this.closed = closed;
        this.sticky = sticky;
        this.type = type;
        this.forum_id = forum_id;
        this.forum = forum ? new Forum(forum) : null;
        this.comment_id = comment_id;
        this.comment = new Comment(comment);
    }
}

export default ForumTopic;
