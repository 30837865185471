import { Forum } from '@/models/Forum';

export const ForumRequest = (argForum) => {
  const forum = argForum.copy()

  if (!forum.id) {
    delete forum.id
  }

  if (forum.cat) {
    forum.cat = forum.cat.id
  }

  return forum
}

export const ForumResponse = (forum) => {
  return new Forum(forum)
}

export const ForumsResponse = (forums) => {
  return forums.map((a) => ForumResponse(a))
}